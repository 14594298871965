<template>
  <div class="container">
    <header>
      <span @click="$router.push('/')">{{ $t('login').Home }}</span>
      <span>{{ $t('login').title }}</span>
      <span></span>
    </header>
    <img class="logo" :src="basicConfig.Logo" alt="" />
    <!-- <div class="nav">
      <div
        class="item"
        @click="activeNav = 0"
        :class="{ activeNav: activeNav === 0 }"
      >
        {{ $t('login').amount }}
      </div>
      <div
        class="item"
        @click="activeNav = 1"
        :class="{ activeNav: activeNav === 1 }"
      >
        {{ $t('login').mailbox }}
      </div>
    </div> -->
    <div class="content">
      <div class="form">
        <div class="item">
          <van-field
            v-model="userName"
            :placeholder="$t('login').placeholder"
          />
        </div>
        <div class="item">
          <van-field
            v-model="password"
            type="password"
            :placeholder="$t('login').placeholder1"
          >
            <template #right-icon>
              <img src="@/assets/new/password.png" class="pwd-icon" alt="" />
            </template>
          </van-field>
        </div>
        <van-checkbox icon-size="15" v-model="checked" shape="square">
          {{ $t('login').jzmm }}</van-checkbox
        >
        <van-button color="#e143ac" block @click="handleLogin">{{
          $t('login').btn
        }}</van-button>
      </div>

      <div class="more">
        <div class="reg">
          {{ $t('login').NoAccount }}
          <span @click="$router.push({ name: 'Register' })">{{
            $t('login').Registration
          }}</span>
        </div>
        <span @click="goServe">{{ $t('login').server }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
export default {
  data() {
    return {
      userName: '',
      password: '',
      checked: false,
      activeNav: 0
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  methods: {
    goServe() {
      location.href = this.basicConfig.ServiceUrl
    },
    async handleLogin() {
      if (this.userName && this.password) {
        const params = {
          username: this.userName,
          password: this.password
        }
        let res = await userApi.getRefreshToken(params)
        res = await userApi.login(res.Token)
        Token.set(res.Token)
        this.loginSuccess()
      } else {
        this.$toast(this.$t('login').fail)
      }
    },
    loginSuccess() {
      this.$toast.success(this.$t('login').success)
      EventBus.$emit('Login')
      setTimeout(() => {
        this.$router.push({ name: 'Home' })
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  .pwd-icon {
    width: 15px;
    height: 15px;
  }
  header {
    background-color: rgb(16, 32, 48);
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    height: 44px;
    span {
      flex: 1;
      &:nth-of-type(2) {
        text-align: center;
      }
      &:nth-of-type(1) {
        font-size: 12px;
      }
    }
  }
  .logo {
    width: 100px;
    height: 100px;
    display: block;
    margin: 30px auto;
  }
  .nav {
    display: flex;
    margin-bottom: 10px;
    .item {
      font-size: 16px;
      height: 40px;
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .activeNav {
      color: #e143ac;
      position: relative;
      &::after {
        content: '';
        width: 20px;
        height: 4px;
        background: #e143ac;
        position: absolute;
        bottom: 0;
      }
    }
  }
  .content {
    padding: 0 10px;

    .form {
      background: #17181e;
      border-radius: 4px;
      padding: 10px;
      .van-cell {
        background: none;
        padding: 16px 0;
        border-bottom: 1px solid #3e536b;
        & /deep/ .van-field__control {
          color: #fff;
        }
      }
      .van-checkbox {
        margin: 15px 0;
        font-size: 12px;
        & /deep/ .van-checkbox__label {
          color: #fff;
        }
      }
      .van-button {
        height: 37px;
        margin-bottom: 10px;
      }
    }
    .more {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      span {
        color: #e143ac;
      }
    }
  }
}
</style>
